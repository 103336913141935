import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"


export const query = graphql`
  {
    allContentfulGallary {
        nodes {
          image {
            id
            gatsbyImageData
          }
        }
    }
  }
`


const Gallery = () => {
    const data = useStaticQuery(query)
    const galleryList = data.allContentfulGallary.nodes[0].image

  return (
    <Layout>
      <Seo title="Gallery"/>
      <section id='home' className='w-full max-w-screen-xl mx-auto' >
        {/* <div className='grid grid-cols-12 gap-8 md:py-10 py-3'>
          <div className='md:col-span-7 col-span-12 content-between grid'>
            <div>
              <h2 className="md:text-8xl text-4xl font-bold">
                My name
              </h2>
              <h2 className="md:leading-normal md:text-8xl text-4xl font-bold ">
                is, Milap Dave
              </h2>
              <p className='md:text-2xl text-xl my-4 '>and I’m taking awesome photos</p>
            </div>
          </div>
          <div className='items-center md:col-span-5 col-span-12 self-center'>
          </div>
        </div> */}
        <div className='grid gap-8 md:py-10 py-3 grid-cols-[repeat(auto-fit,_minmax(300px,_1fr))]'>
            {galleryList.map(item => {
                const imageData = getImage(item)
            return(
                <div className='relative overflow-hidden bg-cover bg-no-repeat rounded-md shadow-2xl '>
                    <GatsbyImage className='overflow-hidden block cursor-pointer transition-all duration-300 grayscale hover:grayscale-0 hover:scale-110 object-cover h-full' image={imageData} alt={''} />
                </div>
            ) 
            })}
        </div>
      </section>
    </Layout>
  )
}

export default Gallery
